import React from 'react';

export default function Title({ mainTitle }) {
    return (
        <div>
            <h1>
                {mainTitle}
            </h1>
        </div>
    );
}
