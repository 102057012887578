import React, { useContext, useState } from 'react';
import { CartContext } from './CartContext';

export default function QuantityBtn({ productInfo }) {

    const { cartItems, setCartItems } = useContext(CartContext);

    // whether the cart contains that product
    let productIndexInCart = cartItems.findIndex((element) => {
        return element.id === productInfo.id;
    });
    // findIndex
    // if that product is in the cart => go to index 0, 1, 2, 3, ....
    // else => go to -1

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart === -1) ? 0 : cartItems[productIndexInCart].quantity
    );

    const handleAdd = () => {

        if (productIndexInCart === -1) {
            // that product is not in the cart, add new element (object) in the cartItems array
            setCartItems(
                [{
                    id: productInfo.id,
                    name: productInfo.name,
                    image: productInfo.image,
                    price: productInfo.price,
                    description: productInfo.description,
                    quantity: 1
                },
                ...cartItems]
            );
        } else {
            // that product is in the cart, just add the quantity
            let newCartArray = [...cartItems];
            newCartArray[productIndexInCart].quantity++;
            setCartItems(newCartArray);
        }

        setNumInCart(numInCart + 1);
    };

    const handleSubtract = () => {

        if (cartItems[productIndexInCart].quantity === 1) {
            // contain only one quantity of that product, remove the object
            let newCartArray = [...cartItems];
            newCartArray.splice(productIndexInCart, 1);
            setCartItems(newCartArray);
        } else {
            // contain more than one quantity of that product is in the cart, just substract the quantity
            let newCartArray = [...cartItems];
            newCartArray[productIndexInCart].quantity--;
            setCartItems(newCartArray);
        }

        setNumInCart(numInCart - 1);
    };

    return (
        <div className="addToCart">
            {
                (numInCart === 0) ?
                    <span className="addToCartBtn" onClick={handleAdd}>加入購物車</span> :
                    <div>
                        <span className="subtractBtn" onClick={handleSubtract}>-</span>
                        {numInCart}件
                        <span className="addBtn" onClick={handleAdd}>+</span>
                    </div>
            }
        </div>
    );
}
