import React from 'react';
import { useState, useEffect } from 'react'; // React Hook, React >= 16.8
import { Link } from 'react-router-dom';
import Title from './Title';
import QuantityBtn from './QuantityBtn';

export default function ProductList() {

    // let productList = [
    //     { "id": 1, "name": "蘋果", "price": 5, "image": "apple.jpg", "description": "新鮮蘋果50克，一日一蘋果，醫生遠離我" },
    //     { "id": 2, "name": "橙", "price": 3, "image": "orange.jpg", "description": "新鮮橙50克，又甜又好吃" },
    //     { "id": 3, "name": "芒果", "price": 4, "image": "mango.jpg", "description": "新鮮芒500克，宜做甜品" },
    //     { "id": 4, "name": "西瓜", "price": 20, "image": "watermelon.jpg", "description": "新鮮西瓜2公斤，夏季必備" },
    //     { "id": 5, "name": "藍梅", "price": 10, "image": "blueberry.jpg", "description": "新鮮藍梅50克，補眼之寶" },
    //     { "id": 6, "name": "白蘿蔔", "price": 5, "image": "white-carrot.jpg", "description": "新鮮白蘿蔔1公斤，宜煲湯" }
    // ];

    const [productList, setProductList] = useState([]);

    // useEffect
    useEffect(() => {
        // 1: no dependency array, trigger time = every component render
        // 2: when dependency array is empty, trigger time = the first render
        // 3: when dependency array contains variable, trigger time = the first render + every time variable changes 
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => setProductList(data));

    }, []); // <== Dependency Array

    return (
        // React Fragment (short form)
        <>
            <Title mainTitle="React入門水果店" />

            <div className="container">
                {
                    productList.map(product => (
                        <React.Fragment key={product.id}>

                            <div className="containerItem">
                                <Link to={'/product/' + product.id}>
                                    <img src={process.env.PUBLIC_URL + '/img/' + product.image} alt={product.name} />
                                </Link>

                                <div className="productName">
                                    {product.name}  -  {product.price}元/件
                                </div>

                                <QuantityBtn productInfo={product} />
                            </div>

                        </React.Fragment>
                    ))
                }
            </div>
        </>
    );
}
