import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// remove StrictMode to prevent run 2 times in useEffect()
// problem in React > 18
// https://flaviocopes.com/react-useeffect-two-times/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
